$(document).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 500) {
            $(".Top").fadeIn();
        } else {
            $(".Top").fadeOut();
        }
    });
    
    $(".Top").click(function() {
        $("html,body").stop().animate({ scrollTop: 0 });
    });

    $(".menu").click(function() {
        $(this).toggleClass("active");
        $("body").toggleClass("menu-open");
    });

    $(".menu-primary a").click(function() {
        $("body").removeClass("menu-open");
        $(".menu").removeClass("active");
    });

    $(".logo a").click(function() {
        $("body").removeClass("menu-open");
        $(".menu").removeClass("active");
    });

});